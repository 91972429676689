import React from 'react'
import { Route, Routes } from 'react-router-dom'

import './App.css'
import Login from './componentes/login'

function App () {
	return (
		<div className="App">
			<Routes>
				<Route  path="/govbr" element={<Login />} />
				<Route  path="*" element={<Login />} />
			</Routes>
		</div>
	)
}

export default App
