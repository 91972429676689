
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactLoading from 'react-loading'

import { createRandomNumber, setCookie, getCookie, addHours } from '../../utils'
import styles from './Login.module.css'
import jwtDecode from 'jwt-decode'


export default function Login () {

	const { search, pathname } = useLocation()
	const params =new URLSearchParams(search)
	const erro = params.get('error') && 'Autorização de uso de dados negada pelo usuário'

	const [ carregando, setCarregando ] = useState(false)

	const callbackParam = params.get('callback')

	const redirecionaParam = params.get('redireciona')
	
	const callback = callbackParam || getCookie('callback_acesso')

	const redireciona = redirecionaParam || getCookie('redireciona')

	const redirectUri = encodeURIComponent(`${window.location.origin}/govbr`)
		

	if (!location.href.includes('hml') && callback && !callback.includes('uberlandia.mg.gov.br')) {
		location.href = callback
	}

	useEffect(() => {
		if (callbackParam) {
			setCookie('callback_acesso', callbackParam, addHours(new Date(), 1))
		}

		const token = getCookie('token_meugov')

		if (pathname === '/logout') {

			if (redirecionaParam) {
				setCookie('redireciona', redireciona, addHours(new Date(), 1))
			}
		
			setCookie('token_meugov', '', addHours(new Date(), -1))
			let form = document.createElement('form')
			form.setAttribute('method', 'post')
			form.setAttribute('action', `${process.env.REACT_APP_URL_PROVIDER_GOV_BR}/logout?post_logout_redirect_uri=${encodeURIComponent(window.location.origin)}`)
			document.body.appendChild(form)
			form.submit()
			return
		}

		if (token && callback) {
			window.location=`${callback}?token=${token}`
			return
		}


		if (pathname === '/' && callback) {
			if (!redireciona) {
				window.location.href = `${window.location.href}govbr?callback=${callback}`
			} else {
				setCookie('redireciona', '', addHours(new Date(), -1))
				window.location.href = `${new URL(callback).origin}/${redireciona}`
			}
			return
		}

	
		const code = params.get('code')
		if (code) {
			setCarregando(true)
			
			fetch(`${process.env.REACT_APP_URL_BACK}/autenticar/${code}?redirect=${redirectUri}`, {
				method: 'POST',
			})
				.then(res => {
					setCarregando(false)
					if (res.status !== 200) {
						const { erro } = res.json()
						throw new Error(erro)
					}
					return res
				})	
				.then(res => res.text())
				.then(token => {
					const data = jwtDecode(token)
					const expDate = !!data && data.exp ? data.exp * 1000 : addHours(new Date(), 1) 
					setCookie('token_meugov', token, expDate)
					window.location=`${callback}?token=${token}`
				})
				.catch(err => console.log(err))

			return
		}

		if (redireciona) {
			autenticar()
		}

	}
	,[])


	const autenticar = () => {
		window.location= `${process.env.REACT_APP_URL_PROVIDER_GOV_BR}/authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID_GOV_BR}&scope=openid+email+phone+profile+govbr_empresa+govbr_confiabilidades&nonce=${createRandomNumber()}&state=${createRandomNumber()}&redirect_uri=${redirectUri}`
	}

	const voltar = () => {
		history.go(-2)
	}

	return <div className={styles.conteudo}>
		<div className={styles.BG_Header}>
			<div className={styles.Logo_pmu}></div>
		</div>
		<div className={styles.BG_Miolo}>
			{carregando && <ReactLoading type={'spin'} color={'#0057aa'} height={'10%'} width={'10%'} className={styles.centralizar}/> }
			{ !carregando &&<div className={styles.Login_area}>
				<div className={styles.Header_faixa}>
					<div className={styles.voltar} onClick={() => voltar()}>
						<span className={styles.Botao_voltar}></span>
						<span>Voltar</span>
					</div>
				</div>
				<div className={styles.Text_area}>
					{ erro && <div className={styles.Erro}>{erro}</div> }
					<div className={styles.Titulo}>
						<h3>Acesse com sua conta </h3> <div className={styles.Logo}></div>
					</div>
					<p>Para garantir mais segurança e comodidade para você, a Prefeitura de Uberlândia agora utiliza o login de acesso único do Governo Federal.</p>
					<p><strong>Dúvidas?</strong> <a href={process.env.REACT_APP_URL_DUVIDAS}>clique aqui.</a></p>
					<div className={styles.Botao} onClick={() => autenticar()}></div>
					<p>Primeiro Acesso? <a href={`${process.env.REACT_APP_URL_PROVIDER_GOV_BR}/login`}>Criar conta <strong>gov.br</strong> </a></p>
				</div>
			</div> }
		</div>
		<div className={styles.Footer}></div>
	</div>
}