export function createRandomNumber () {
	return (createRandomPart()+createRandomPart()+createRandomPart()+createRandomPart()).substring(0,32)
}

function createRandomPart () {
	return Math.random().toString(36).replace(/[^a-z 0-9]+/g, '')
}

export function setCookie (chave, valor, milisegundos) {
	const expires = 'expires='+new Date(milisegundos).toUTCString()
	document.cookie = chave + '=' + valor + ';' + expires + ';path=/'
}

export function addHours (date, hours) {
	date.setHours(date.getHours() + hours)
	return date
}
  
export function getCookie (chave) {
	let nome = chave + '='
	let ca = document.cookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) == ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(nome) == 0) {
			return c.substring(nome.length, c.length)
		}
	}
	return ''
}